import React from "react";
import Helmet from "react-helmet";


export default (props) => (
    <Helmet>
      <meta name="description" content={props.seodesc} />
      <meta name="image" content={props.socialImage} />

      {/* OpenGraph tags */}
      <meta property="og:title" content={props.socialTitle} />
      <meta property="og:description" content={props.seodesc} />
      <meta property="og:image" content={props.socialImage} />
      <meta property="og:type" content="website" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={props.twitter} />
      <meta name="twitter:title" content={props.socialTitle} />
      <meta name="twitter:description" content={props.seodesc} />
      <meta name="twitter:image" content={props.socialImage} />
    </Helmet>
)
